import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { DirectoryGroupUpdateMemberRequest } from "src/app/models/directory/groups/DirectoryGroupUpdateMemberRequest";
import { DirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { IdampHttpClient } from "../common/IdampHttpClient";
import { IDirectoryGroupFilter } from "src/app/models/services/IDirectoryGroupFilter";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { CaseInsensitiveComparer } from "src/app/helpers/caseInsensitiveComparer";
import { AadGroupSearchScenario } from "src/app/models/directory/enums/AadGroupSearchScenario.enum";
import { DirectoryGroupCreateRequest } from "src/app/models/directory/groups/DirectoryGroupCreateRequest";


@Injectable({ providedIn: 'root' })

export class DirectoryGroupService {

    private readonly LcmIdentifier: string = 'idamp.azure.chevron.com';
    private readonly DefaultLimit: number = 100;
    private readonly DefaultAllLimit: number = 99999;

    constructor(private http: IdampHttpClient) { }

    public SearchGroups(term: string, domain: string, filters?: IDirectoryGroupFilter, limit?: number, aadGroupSearchScenario?: AadGroupSearchScenario): Observable<IGenericApiResponse<DirectoryGroup[]>> {
        return from(this.SearchGroupsAsync(term, domain, filters, limit, aadGroupSearchScenario));
    }

    public async SearchGroupsAsync(term: string, domain: string, filters?: IDirectoryGroupFilter, limit?: number, aadGroupSearchScenario?: AadGroupSearchScenario): Promise<IGenericApiResponse<DirectoryGroup[]>> {
        const path = `groups/${domain}`;
        const query = { searchTerm: term, resultSize: limit ?? this.DefaultLimit, aadGroupSearchScenario: aadGroupSearchScenario ?? AadGroupSearchScenario.AzureAssignable };

        let results = await this.http.GetAsync<IGenericApiResponse<DirectoryGroup[]>>(path, query);

        let filtered = this.ApplyFilters(results.data, filters);

        return {
            message: results.message,
            data: filtered
        };
    }

    public GetOwnedGroups(upn?: string, filters?: IDirectoryGroupFilter, limit?: number): Observable<IGenericApiResponse<DirectoryGroup[]>> {
        return from(this.GetOwnedGroupsAsync(upn, filters, limit));
    }

    public async GetOwnedGroupsAsync(upn?: string, filters?: IDirectoryGroupFilter, limit?: number): Promise<IGenericApiResponse<DirectoryGroup[]>> {
        const path = upn == undefined ?
            `me/ownedObjects/groups` :
            `users/${upn}/ownedObjects/groups`;
        const query = { resultSize: limit ?? this.DefaultAllLimit };

        let results = await this.http.GetAsync<IGenericApiResponse<DirectoryGroup[]>>(path, query);

        let filtered = this.ApplyFilters(results.data, filters);

        return {
            message: results.message,
            data: filtered
        };
    }

    public GetByIdAsync(id: string, domain: string): Promise<IGenericApiResponse<DirectoryGroup>> {
        const path = `groups/${domain}/${id}`;
        return this.http.GetAsync<IGenericApiResponse<DirectoryGroup>>(path);
    }

    public GetOwnersAsync(id: string, domain: string): Promise<IGenericApiResponse<DirectoryUser[]>> {
        const path = `groups/${domain}/${id}/owners`;
        return this.http.GetAsync<IGenericApiResponse<DirectoryUser[]>>(path);
    }

    public GetMembersAsync(id: string, domain: string, limit?: number): Promise<IGenericApiResponse<DirectoryUser[]>> {
        const path = `groups/${domain}/${id}/members`;
        const query = { resultSize: limit ?? this.DefaultAllLimit }

        return this.http.GetAsync<IGenericApiResponse<DirectoryUser[]>>(path, query);
    }

    /**
     * Used to check if a user is a member of the group
     * @param groupId guid id of the group
     * @param domain domain name for the group to check
     * @param userId user object id to check, should only use the object id
     * @returns directory user object if found, otherwise null
     */
    public async CheckMemberAsync(groupId: string, domain: DirectoryDomain, userId: string): Promise<DirectoryUser | null> {
        const result = await this.SearchGroupMembersAsync(groupId, domain, userId);

        if (result.length === 1) {
            return result[0];
        }

        return null;
    }

    public async SearchGroupMembersAsync(groupId: string, domain: DirectoryDomain, term: string, limit?: number): Promise<DirectoryUser[]> {
        const path = `groups/${domain}/${groupId}/members`;
        const query = { searchTerm: term, resultSize: limit ?? this.DefaultLimit };

        const result = await this.http.GetAsync<IGenericApiResponse<DirectoryUser[]>>(path, query);

        return result.data;
    }

    public UpdateMembership(id: string, domain: string, memberChanges: DirectoryGroupUpdateMemberRequest): Observable<IGenericApiResponseWithWorkflowRequest<string>> {
        const path = `groups/${domain}/${id}/members`;
        return from(this.http.PatchAsync<DirectoryGroupUpdateMemberRequest, IGenericApiResponseWithWorkflowRequest<string>>(path, memberChanges));
    }

    public CreateGroup(domain: string, model: DirectoryGroupCreateRequest): Observable<IGenericApiResponseWithWorkflowRequest<DirectoryGroup>>
    {
        const path = `groups/${domain}`;
        return from(this.http.PostAsync<DirectoryGroupCreateRequest, IGenericApiResponseWithWorkflowRequest<DirectoryGroup>>(path, model))
    }

    private ApplyFilters(groups: DirectoryGroup[], filters?: IDirectoryGroupFilter): DirectoryGroup[] {
        let results = groups;

        if (filters?.removeNotAvailableAsGroupMember) {
            results = results.filter((x: DirectoryGroup) => !x.settings?.notAvailableToAddAsGroupMemberViaUI);
        }

        if (filters?.removeDynamicMembershipEnabled) {
            results = results.filter((x: DirectoryGroup) => !x.dynamicGroupMembership);
        }

        if (filters?.onlyManagedByIdamp) {
            results = results.filter((x: DirectoryGroup) => CaseInsensitiveComparer(x.lifeCycleManagementProcess, this.LcmIdentifier));
        }

        if (filters?.filterGroupRemoveNonAzureGroups) {
            results = results.filter((x: DirectoryGroup) => x.domain.indexOf(".onmicrosoft.com") > -1);
        }

        return results;
    }
}