export enum UserType {
    Primary = 'Primary',
    Service = 'Service',
    Guest = 'Guest',
    Coco = 'COCO',
    Admin = 'Admin',
    GroupMailbox = 'Group Mailbox',
    SharedMailbox = 'Shared Mailbox',
    Privilege = 'Privilege',
    SmartBadge = 'SmartBadge',
}