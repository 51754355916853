<form class="branded text-left" [formGroup]="updateForm" novalidate>
    <div class="row">
        @if(userInfo.type === UserType.Guest || userInfo.type === UserType.Coco) {
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="First Name">
                <mat-label>first name</mat-label>
                <input matInput formControlName="givenName">
                @if(updateForm.controls.givenName.errors?.required ?? false) {
                <mat-error>
                    First Name is required
                </mat-error>}
                @if(updateForm.controls.givenName.errors?.pattern ?? false) {
                <mat-error>
                    First Name is invalid
                </mat-error>}
                @if(updateForm.controls['givenName'].hasError('maxlength')) {
                <mat-error>
                    First Name must be no more than {{
                    updateForm.controls.givenName.errors?.maxlength.requiredLength }}
                    characters
                </mat-error>}
            </mat-form-field>
        </div>
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Last Name">
                <mat-label>last name</mat-label>
                <input matInput formControlName="surname">
                @if(updateForm.controls.surname.errors?.required ?? false) {
                <mat-error>
                    Last Name is required
                </mat-error>}
                @if(updateForm.controls.surname.errors?.pattern ?? false) {
                <mat-error>
                    Last Name is invalid
                </mat-error>}
                @if(updateForm.controls['surname'].hasError('maxlength')) {
                <mat-error>
                    Last Name must be no more than {{ updateForm.controls.surname.errors?.maxlength.requiredLength
                    }}
                    characters
                </mat-error>}
            </mat-form-field>
        </div>
        }
        @if(userInfo.type === UserType.Coco) {
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Title">
                <mat-label>title</mat-label>
                <mat-select formControlName="jobTitle" required>
                    <mat-option *ngFor="let title of titles" [value]="title">{{ title }}</mat-option>
                </mat-select>
                @if(updateForm.controls.jobTitle.errors?.required ?? false) {
                <mat-error>
                    Title is required
                </mat-error>}
            </mat-form-field>
        </div>
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="Station Number should contain only numbers">
                <mat-label>station number</mat-label>
                <input matInput formControlName="stationNumber" required>
                <mat-error *ngIf="updateForm.controls.stationNumber.errors">
                    {{getStationNumberErrorMessage(updateForm)}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="Telephone Number should look like 1234567890 or 123-456-7890">
                <mat-label>telephone number</mat-label>
                <input matInput formControlName="telephoneNumber" required>
                @if(updateForm.controls.telephoneNumber.errors) {
                <mat-error>
                    {{getTelephoneNumberErrorMessage(updateForm)}}
                </mat-error>}
            </mat-form-field>
        </div>
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="Mobile Number should look like 1234567890 or 123-456-7890">
                <mat-label>mobile number</mat-label>
                <input matInput formControlName="mobilePhone">
                @if(updateForm.controls.mobilePhone.errors){
                <mat-error>
                    {{getMobileNumberErrorMessage(updateForm)}}
                </mat-error>}
            </mat-form-field>
        </div>
        }
        @if(userInfo.type === UserType.Service) {
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="Service Id should contain only numbers">
                <mat-label>new service id</mat-label>
                <input matInput formControlName="applicationId">
                @if(updateForm.controls.applicationId.errors) {
                <mat-error>
                    {{getApplicationIdErrorMessage(updateForm)}}
                </mat-error>}
            </mat-form-field>
        </div>
        }
        @if(userInfo.type !== UserType.Coco) {
        <div class="col-4 pt-3">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="{{stewardSponsorTooltipMessage}}">
                <mat-label>new {{stewardSponsorLabel}}</mat-label>
                <input matInput [readonly]="true" formControlName="selectedSteward" (click)="searchForUsers()">
                <button mat-icon-button matSuffix (click)="searchForUsers()">
                    <mat-icon>person_search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        }
    </div>
</form>