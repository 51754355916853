import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { NextPage } from '@cvx/nextpage';
import { CalAngularModule } from '@cvx/cal-angular';
import { HomeComponent } from './components/home/home.component';
import { DefaultComponent } from './components/default/default.component';
import { RetireGuestComponent } from './components/account/guest/retire-guest/retire-guest.component';
import { UpdateGuestComponent } from './components/account/guest/update-guest/update-guest.component';
import { InviteGuestComponent } from './components/account/guest/invite-guest/invite-guest.component';
import { MyProfileComponent } from './components/account/selfService/my-profile/my-profile.component';
import { CreatePrimaryUserComponent } from './components/account/primaryUserAccounts/create-primary-user/create-primary-user.component';
import { ManagePrimaryUserComponent } from './components/account/primaryUserAccounts/manage-primary-user/manage-primary-user.component';
import { BulkManagePrimaryUserComponent } from './components/account/primaryUserAccounts/bulk-manage-primary-user/bulk-manage-primary-user.component';
import { BulkCreatePrimaryUserComponent } from './components/account/primaryUserAccounts/bulk-create-primary-user/bulk-create-primary-user.component';
import { PrimaryUserDetailsComponent } from './components/_shared/primary-user-details/primary-user-details.component';
import { SearchPrimaryUserComponent } from './components/account/primaryUserAccounts/search-primary-user/search-primary-user.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PrimaryUserSearchComponent } from './components/_shared/primary-user-search/primary-user-search.component';
import { MatSortModule } from '@angular/material/sort';
import { DialogPrimaryUserSearchComponent } from './components/_shared/dialog-primary-user-search/dialog-primary-user-search.component';
import { DialogLocationSearchComponent } from './components/_shared/dialog-location-search/dialog-location-search.component';
import { DialogCampEntitySearchComponent } from './components/_shared/dialog-camp-entity-search/dialog-camp-entity-search.component';
import { DialogPopUpBoxComponent } from './components/_shared/dialog-pop-up-box/dialog-pop-up-box.component';
import { CreateServicePrivilegeAccountComponent } from './components/account/servicePrivilegeAccounts/create-service-privilege-account/create-service-privilege-account.component';
import { UpdateServiceAccountComponent } from './components/account/servicePrivilegeAccounts/update-service-account/update-service-account.component';
import { MfaResetComponent } from './components/account/mfa-reset/mfa-reset.component';
import { CreateApplicationComponent } from './components/applications/create-application/create-application';
import { AppSearchComponent } from './components/_shared/app-search/app-search.component';
import { DialogAppSearchComponent } from './components/_shared/dialog-app-search/dialog-app-search.component';
import { RequestPermissionsComponent } from './components/applications/request-permissions/request-permissions';
import { DisplayFormComponent } from './components/_shared/display-form/display-form.component';
import { DisplayRequestComponent } from './components/_shared/display-requests/display-requests.component';
import { UpdateMembershipComponent } from './components/groups/update-membership/update-membership.component';
import { GenericSearchResultsComponent } from './components/_shared/generic-search-results/generic-search-results.component';
import { DirectorySearchComponent } from './components/_shared/directory-search/directory-search.component';
import { DialogDirectorySearchComponent } from './components/_shared/dialog-directory-search/dialog-directory-search.component';
import { DialogGenericSearchResultsComponent } from './components/_shared/dialog-generic-search-results/dialog-generic-search-results.component';
import { RetireServicePrivilegeAccountComponent } from './components/account/servicePrivilegeAccounts/retire-service-privilege-account/retire-service-privilege-account.component';
import { MyApprovalRequestsComponent } from './components/requests/my-approval-requests/my-approval-requests.component';
import { MyRequestsComponent } from './components/requests/my-requests/my-requests.component';
import { RequestResultsComponent } from './components/_shared/request-results/request-results.component';
import { DetailsRequestsComponent } from './components/requests/details-requests/details-requests.component';
import { RequestDetailsAppPermissionsChange } from './components/_shared/request-details-app-permissions-change/request-details-app-permissions-change.component';
import { RequestDetailsGroupMembershipChange } from './components/_shared/request-details-group-membership-change/request-details-group-membership-change.component';
import { RequestDetailsApprovals } from './components/_shared/request-details-approvals/request-details-approvals.component';
import { RequestDetailsAppCreate } from './components/_shared/request-details-app-create/request-details-app-create.component';
import { AppConfigService } from './services/common/AppConfigService';
import { TextFormatterComponent } from './components/_shared/text-formatter/text-formatter.component';
import { DialogBatchDirectorySearchComponent } from './components/_shared/dialog-batch-directory-search/dialog-batch-directory-search.component';
import { ApiStatusComponent } from './components/_shared/api-status/api-status.component';
import { DialogDirectoryGroupMemberSearchComponent } from './components/_shared/dialog-directory-group-member-search/dialog-directory-group-member-search.component';
import { DirectoryGroupMemberSearchComponent } from './components/_shared/directory-group-member-search/directory-group-member-search.component';
import { DisplayHttpErrorComponent } from './components/_shared/display-http-error/display-http-error.component';
import { CreatePasswordComponent } from './components/passwords/create-password/create-password.component';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';
import { DeletePasswordComponent } from './components/passwords/delete-password/delete-password.component';
import { RequestDetailsSecretCreate } from './components/_shared/request-details-secret-create/request-details-secret-create.component';
import { RequestDetailsSecretDelete } from './components/_shared/request-details-secret-delete/request-details-secret-delete.component';
import { RequestDetailsUserDelete } from './components/_shared/request-details-user-delete/request-details-user-delete.component';
import { UpdatePasswordPermissionsComponent } from './components/passwords/update-password-permissions/update-password-permissions.component';
import { RequestDetailsSecretUpdatePermissions } from './components/_shared/request-details-secret-update-permissions/request-details-secret-update-permissions.component';
import { RequestDetailsGuestInvite } from './components/_shared/request-details-guest-invite/request-details-guest-invite.component';
import { RequestDetailsGuestUpdate } from './components/_shared/request-details-guest-update/request-details-guest-update.component';
import { RequestPasswordPermissionsComponent } from './components/passwords/request-password-permissions/request-password-permissions.component';
import { RetireCocoUserComponent } from './components/account/cocoUsers/retire-coco-user/retire-coco-user.component';
import { CreateCocoUserComponent } from './components/account/cocoUsers/create-coco-user/create-coco-user.component';
import { UpdateCocoUserComponent } from './components/account/cocoUsers/update-coco-user/update-coco-user.component';
import { ContractorAccessManagementComponent } from './components/account/primaryUserAccounts/contractor-access-management/contractor-access-management.component';
import { AuthCodeValidationComponent } from './components/support/auth-code-validation/auth-code-validation.component';
import { GenericReusableTableComponent } from './components/_shared/generic-reusable-table/generic-reusable-table.component';
import { DialogProgressComponent } from './components/_shared/dialog-progress/dialog-progress.component';
import { UnlockUsersComponent } from './components/account/unlockUsers/unlock-users.component';
import { RequestDetailsAccountUnlock } from './components/_shared/request-details-account-unlock/request-details-account-unlock.component';
import { AssignAppRoleComponent } from './components/applications/assign-app-role/assign-app-role.component';
import { RegisterClientSecretComponent } from './components/applications/register-client-secret/register-client-secret.component';
import { RequestDetailsAppRoleAssignment } from './components/_shared/request-details-app-role-assignment/request-details-app-role-assignment.component';
import { FilterInputComponent } from './components/_shared/filter-input/filter-input.component';
import { RequestDetailsServiceAccountUpdate } from './components/_shared/request-details-service-account-update/request-details-service-account-update.component';
import { RequestDetailsCocoCreate } from './components/_shared/request-details-coco-create/request-details-coco-create.component';
import { RequestDetailsAppRegisterClientSecret } from './components/_shared/request-details-app-register-client-secret/request-details-app-register-client-secret';
import { DeleteUserComponent } from './components/_shared/delete-user/delete-user.component';
import { RequestablePermissionsComponent } from './components/_shared/requestable-permissions/requestable-permissions.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CreateGroupComponent } from './components/groups/create-group/create-group.component';
import { RequestDetailsGroupCreate } from './components/_shared/request-details-group-create/request-details-group-create.component';
import { RequestDetailsMfaReset } from './components/_shared/request-details-reset-mfa/request-details-mfa-reset.component';
import { RequestDetailsUserCreate } from './components/_shared/request-details-service-account-create/request-details-user-create.component';
import { RequestDetailsCocoUpdate } from './components/_shared/request-details-coco-update/request-details-coco-update.component';
import { RevokeAppPermissionComponent } from './components/applications/revoke-app-permissions/revoke-app-permission.component';
import { RequestDetailsAppPermissionRevoke } from './components/_shared/request-details-app-permission-revoke/request-details-app-permission-revoke.component';
import { DeleteClientSecretComponent } from './components/applications/delete-client-secret/delete-client-secret.component';
import { RequestDetailsAppDeleteClientSecret } from './components/_shared/request-details-app-delete-client-secret/request-details-app-delete-client-secret.component';
import { ConvertDisplayLabelsPipe } from "./pipes/convert-display-labels.pipe";
import { UserUpdateComponent } from './components/_shared/user-update/user-update.component';
import { UserUpdateListComponent } from './components/_shared/user-update/user-update-list/user-update-list.component';
import { UserUpdateFormComponent } from './components/_shared/user-update/user-update-form/user-update-form.component';
import { CreateMailboxAccountComponent } from './components/account/mailbox/create-mailbox-account/create-mailbox-account.component';
import { RequestDetailsMailboxCreateComponent } from './components/_shared/request-details-mailbox-create/request-details-mailbox-create.component';
import { AppSearchChevronComponent } from './components/_shared/app-search-chevron/app-search-chevron.component';
import { DialogAppSearchChevronComponent } from './components/_shared/dialog-app-search-chevron/dialog-app-search-chevron.component';
import { MapEnvironmentToColorClassPipe } from './pipes/map-environment-to-color-class.pipe';


@Injectable()
export class IdampAppRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // only reuse componet if the route has specifies
    // data: {reuseComponent: true}

    return future.data.reuseComponent;
  }
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DefaultComponent,
        FilterInputComponent,
        RetireGuestComponent,
        UpdateGuestComponent,
        InviteGuestComponent,
        MyProfileComponent,
        CreatePrimaryUserComponent,
        ManagePrimaryUserComponent,
        BulkManagePrimaryUserComponent,
        BulkCreatePrimaryUserComponent,
        PrimaryUserDetailsComponent,
        SearchPrimaryUserComponent,
        PrimaryUserSearchComponent,
        DialogPrimaryUserSearchComponent,
        DialogLocationSearchComponent,
        DialogCampEntitySearchComponent,
        DialogPopUpBoxComponent,
        ContractorAccessManagementComponent,
        AuthCodeValidationComponent,
        CreateServicePrivilegeAccountComponent,
        UpdateServiceAccountComponent,
        MfaResetComponent,
        RetireServicePrivilegeAccountComponent,
        GenericReusableTableComponent,
        CreateCocoUserComponent,
        UpdateCocoUserComponent,
        RetireCocoUserComponent,
        DialogProgressComponent,
        DialogPopUpBoxComponent,
        CreateApplicationComponent,
        AppSearchComponent,
        DialogAppSearchComponent,
        RequestPermissionsComponent,
        DisplayFormComponent,
        DisplayRequestComponent,
        UpdateMembershipComponent,
        GenericSearchResultsComponent,
        DirectorySearchComponent,
        DialogDirectorySearchComponent,
        DialogGenericSearchResultsComponent,
        MyApprovalRequestsComponent,
        MyRequestsComponent,
        RequestResultsComponent,
        DetailsRequestsComponent,
        RequestDetailsAppRoleAssignment,
        RequestDetailsAppPermissionsChange,
        RequestDetailsGroupMembershipChange,
        RequestDetailsApprovals,
        RequestDetailsAppCreate,
        TextFormatterComponent,
        DialogBatchDirectorySearchComponent,
        ApiStatusComponent,
        DialogDirectoryGroupMemberSearchComponent,
        DirectoryGroupMemberSearchComponent,
        DisplayHttpErrorComponent,
        CreatePasswordComponent,
        DeletePasswordComponent,
        RequestDetailsSecretCreate,
        RequestDetailsSecretDelete,
        RequestDetailsUserDelete,
        UpdatePasswordPermissionsComponent,
        RequestDetailsSecretUpdatePermissions,
        RequestDetailsGuestInvite,
        RequestDetailsGuestUpdate,
        RequestDetailsAppRegisterClientSecret,
        RequestPasswordPermissionsComponent,
        UnlockUsersComponent,
        RequestDetailsAccountUnlock,
        AssignAppRoleComponent,
        RevokeAppPermissionComponent,
        RequestDetailsAppPermissionRevoke,
        RequestDetailsServiceAccountUpdate,
        RequestDetailsCocoCreate,
        RequestDetailsUserCreate,
        RegisterClientSecretComponent,
        DeleteUserComponent,
        RequestablePermissionsComponent,
        CreateGroupComponent,
        RequestDetailsGroupCreate,
        RequestablePermissionsComponent,
        RequestDetailsMfaReset,
        RequestDetailsCocoUpdate,
        AuthCodeValidationComponent,
        DeleteClientSecretComponent,
        RequestDetailsAppDeleteClientSecret,    
        UserUpdateListComponent,
        UserUpdateComponent,
        UserUpdateFormComponent,
        CreateMailboxAccountComponent,
        RequestDetailsMailboxCreateComponent,
        AppSearchChevronComponent,
        DialogAppSearchChevronComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: IdampAppRouteReuseStrategy
        }
    ],
    bootstrap: [AppComponent],
    imports: [
        ClipboardModule,
        BrowserModule,
        AppRoutingModule,
        CalAngularModule.forRoot('assets/config.json'),
        NextPage,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxMatIntlTelInputComponent,
        MatSortModule,
        MaterialModule,
        ConvertDisplayLabelsPipe,
        MapEnvironmentToColorClassPipe,
    ]
})

export class AppModule { }

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}
