<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="createdRequests.length === 0">
            <div class="col col-12">
                <h1>update group membership</h1>

                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>group</strong></p></legend>
                    
                    <api-status class="mb-4"></api-status>

                    <directory-search
                        [type]="GroupMemberType.Group"
                        [groupOwned]="true"
                        [groupLoadMembers]="true"
                        [filterGroupRemoveNotAvailableAsGroupMember]="false"
                        [filterGroupRemoveDynamicMembershipEnabled]="true"
                        [filterGroupOnlyManagedByIdamp]="true"
                        (objectSelected)="groupSelected($event)"
                        *ngIf="selectedGroup == undefined && showOwnedSearch"
                    ></directory-search>

                    <div *ngIf="selectedGroup !== undefined">
                        <display-form [displayObject]="groupDisplayModel"></display-form>
                        <div class="alert alert-info mt-3" role="alert">
                            Adding Contacts and Computers to a group is not available at this time
                        </div>
                    </div>
                    
                </fieldset>

                <fieldset class="pb-3" *ngIf="selectedGroup !== undefined">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>add members</strong></p></legend>

                    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon> Add Member</button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item *ngIf="allowedTypes.primaryUsers" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.Primary)">users</button>
                      <button mat-menu-item *ngIf="allowedTypes.groups" (click)="openMemberSearchDialog(GroupMemberType.Group)">groups</button>
                      <button mat-menu-item *ngIf="allowedTypes.adminAccounts" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.Admin)">admin accounts</button>
                      <button mat-menu-item *ngIf="allowedTypes.privilegedAccounts" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.Privilege)">privileged accounts</button>
                      <button mat-menu-item *ngIf="allowedTypes.serviceAccounts" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.Service)">service accounts</button>
                      <button mat-menu-item *ngIf="allowedTypes.servicePrincipals" (click)="openMemberSearchDialog(GroupMemberType.ServicePrincipal)">service principals</button>
                      <button mat-menu-item *ngIf="allowedTypes.groupMailbox" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.GroupMailbox)">group mailbox</button>
                      <button mat-menu-item *ngIf="allowedTypes.sharedMailbox" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.SharedMailbox)">shared mailbox</button>
                      <button mat-menu-item *ngIf="allowedTypes.smartBadge" (click)="openMemberSearchDialog(GroupMemberType.User, UserType.SmartBadge)">smart badge</button>
                    </mat-menu>
                    <button mat-flat-button color="primary" type="button" class="add-button ml-2" (click)="openBatchAddMembers(true)" *ngIf="allowedTypes.primaryUsers">Batch Add Users</button>

                    <div class="mt-3 mb-3" [hidden]="!isAddMemberLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                    <div class="container mb-3 mt-3" [hidden]="addMemberErrorMessage===''">
                        <p class="text-danger text-center">{{addMemberErrorMessage}}</p>
                    </div>

                    <generic-search-results 
                        [showHeader]="false" 
                        [records]="selectedMembers" 
                        [showCounter]="false"
                        [title]="''" 
                        [displayedColumns]="memberDisplayedColumns" 
                        [selectableRecords]="false"
                        [deletableRecords]="true"
                        (recordDeleted)="removeFromNewMember($event)">
                    </generic-search-results>
                </fieldset>

                <fieldset class="pb-3" *ngIf="selectedGroup !== undefined">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>remove members</strong></p></legend>

                    <div class="container mb-3" [hidden]="(this.selectedGroup?.members ?? []).length < 1000">
                        <p class="text-info text-center">This group contains more than 1000 members, remove members table will be incomplete.  Batch Remove Users can be used to search against all members.</p>
                    </div>

                    <button mat-flat-button color="primary" (click)="openAddToRemoveMembers()" type="button" class="add-button"><mat-icon>delete</mat-icon> member</button>
                    <button mat-flat-button color="primary" type="button" class="add-button ml-2" (click)="openBatchAddMembers(false)" *ngIf="allowedTypes.primaryUsers">Batch Remove Users</button>

                    <div class="mt-2 mb-3" [hidden]="!isRemoveMemberLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                    <div class="container mb-3 mt-3" [hidden]="removeMemberErrorMessage===''">
                        <p class="text-danger text-center">{{removeMemberErrorMessage}}</p>
                    </div>

                    <generic-search-results 
                        [showHeader]="false" 
                        [records]="selectedRemoveMembers" 
                        [showCounter]="false"
                        [title]="''" 
                        [displayedColumns]="memberDisplayedColumns"
                        [selectableRecords]="false"
                        [deletableRecords]="true"
                        (recordDeleted)="removeFromRemoveMembers($event)">
                    </generic-search-results> 
                </fieldset>

                <mat-divider *ngIf="selectedGroup !== undefined"></mat-divider>

                <fieldset class="pb-3" *ngIf="selectedGroup !== undefined">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>justification</strong></p></legend>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100"
                            subscriptSizing="dynamic">
                                <mat-label>justification</mat-label>
                                <input matInput [formControl]="justification" matTooltip="Justification for group membership change">
                                <mat-error>Type is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>

                <mat-divider></mat-divider>

                <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="selectedGroup !== undefined">
                    <mat-spinner [diameter]="30" *ngIf="isCreating" style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group" *ngIf="!isCreating && (selectedMembers.length > 0 || selectedRemoveMembers.length > 0)">
                        <button class="button" type="submit" (click)="onSubmit()">update</button>
                    </div>
                    <div class="btn-group mr-2" role="group" *ngIf="!isCreating">
                        <button class="button" type="button" (click)="resetForm()">reset</button>                                 
                    </div>
                </div>  
            </div>
        </div>
        <div class="row" *ngIf="createdRequests.length > 0">
            <div class="col col-12">
                <h1>request submitted</h1>

                <display-requests [requests]="createdRequests"></display-requests>
            </div>
        </div>
    </section>
</main>
