import { Component, Input, OnInit } from "@angular/core";
import { DataFormatter } from "src/app/services/common/DataFormatter";

@Component({
    selector: 'text-formatter',
    templateUrl: './text-formatter.component.html'
})
export class TextFormatterComponent implements OnInit {
    @Input() content:string;

    formatted:string;

    constructor(private formatter:DataFormatter) {
    }

    ngOnInit(): void {
        this.formatted = this.formatter.format(this.content);
    }
}