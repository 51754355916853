import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';
import CountriesList from '../../guest/invite-guest/CountriesList';
import {
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { CreatePrivilegeAccountRequest } from 'src/app/models/servicePrivilegeAccounts/CreatePrivilegeAccountRequest';
import { CreateServiceAccountRequest } from 'src/app/models/servicePrivilegeAccounts/CreateServiceAccountRequest';

@Component({
  selector: 'app-create-service-privilege-account',
  templateUrl: './create-service-privilege-account.component.html',
  styleUrls: ['./create-service-privilege-account.component.css'],
})
export class CreateServicePrivilegeAccountComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  isCreateProcessActive: boolean = false;

  createdUser: IGenericApiResponseWithWorkflowRequest<IDirectoryUser> | undefined;
  displayCreatedUser: any;
  createErrorMessage = { message: '', errors: [] };

  permanentAddresses = CountriesList;
  filterOptions: Observable<any[]> = of(CountriesList);
  createUserForm: FormGroup = new FormGroup({});
  constructor(
    private authService: CalAngularService,
    private formBuilder: FormBuilder,
    private graphUserService: GraphUserService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.filterOptions = this.createUserForm.controls[
      'residenceName'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string)
          : this.permanentAddresses.slice();
      })
    );
  }

  validateAllFields(formGroup: FormGroup) {
    formGroup.updateValueAndValidity();
  }

  private _filter(value: string): Array<{ name: string; code: string }> {
    const filterValue = value.toLowerCase();
    return this.permanentAddresses.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(data: any): string {
    return data.name;
  }

  onValueSelected = (data: any) => {
    this.createUserForm.patchValue({ usageLocationCode: data.code });
    this.createUserForm.get('residenceName')?.setErrors(null);
  };

  initForm() {
    this.createUserForm = this.formBuilder.group({
      givenName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      accountType: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      applicationId: ['',
        Validators.compose([
          Validators.pattern(ValidationPatternConstants.NumberPattern),
        ]),
      ],
      usageLocationCode: [''],
      residenceName: [''],
      trainingCompletionCheckbox: [false, Validators.compose([Validators.required])],
    });
    this.formFieldChanges();
  }

  formFieldChanges() {
    this.createUserForm
      .get('accountType')
      ?.valueChanges.subscribe((value: string) => {
        const givenNameControl =
          this.createUserForm.get('givenName');
        const residenceNameControl =
          this.createUserForm.get('residenceName');
        if (value === UserType.Service) {
          residenceNameControl?.setValidators([
            Validators.required,
            Validators.pattern(ValidationPatternConstants.LocationNamePattern),
          ]);
          residenceNameControl?.updateValueAndValidity();

          givenNameControl?.setValidators([
            Validators.required,
            Validators.maxLength(64),
            Validators.pattern(ValidationPatternConstants.NamePattern),
          ]);
          this.createUserForm.patchValue({
            givenName: '',
          });
          givenNameControl?.updateValueAndValidity();
        } else if (value === UserType.Privilege) {
          const mail: string = this.authService.cvxClaimsPrincipal.email;
          this.createUserForm.patchValue({
            givenName: `${mail.substring(0, mail.indexOf("@"))}-ps`,
            residenceName: '',
            usageLocationCode: ''
          });
          givenNameControl?.updateValueAndValidity();
          residenceNameControl?.clearValidators();
          residenceNameControl?.updateValueAndValidity();
        }
      });
  }


  get trainingCompleted(): boolean {
    return this.createUserForm.get('trainingCompletionCheckbox')?.value ?? false;
  }


  onSubmit() {
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];
    this.validateAllFields(this.createUserForm);

    //check training completion
    const statement = document.getElementById('confirmStatement');
    if (statement != null) statement.style.color = this.trainingCompleted ? 'black' : 'red';

    if (this.createUserForm?.valid && this.trainingCompleted) {
      if (this.createUserForm?.value.accountType === UserType.Service)
        this.createServiceAccount(this.getCreateServiceAccountRequest());
      else
        this.createPrivilegeAccount(this.getCreatePrivilegeAccountRequest());
    }
  }

  private getCreateServiceAccountRequest(): CreateServiceAccountRequest {
    const formData = this.createUserForm?.value;
    const createServiceAccountRequest = new CreateServiceAccountRequest();
    createServiceAccountRequest.givenName = formData.givenName;
    createServiceAccountRequest.usageLocationCode = formData.usageLocationCode;
    createServiceAccountRequest.applicationId = formData.applicationId;
    createServiceAccountRequest.steward = this.authService.cvxClaimsPrincipal.objectId;
    return createServiceAccountRequest;
  }

  private getCreatePrivilegeAccountRequest(): CreatePrivilegeAccountRequest {
    const createPrivilegeAccountRequest = new CreatePrivilegeAccountRequest();
    createPrivilegeAccountRequest.steward = this.authService.cvxClaimsPrincipal.objectId;
    return createPrivilegeAccountRequest;
  }

  private processCreateAccountResponse() {
    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser>) => {
        this.createdUser = x;
        let data = x.data as IDirectoryUser;
        this.displayCreatedUser = {
          id: data.id,
          name: data.displayName,
          mail: data.userPrincipalName
        };
        this.createErrorMessage = { message: '', errors: [] };
      },
      error: (err: any) => {
        this.createErrorMessage.message = err.statusText;
        this.createErrorMessage.errors = err?.error?.errors ?? [];
        this.isCreateProcessActive = false;
      },
      complete: () => {
        this.isCreateProcessActive = false;
      }
    };
    return observer;
  }

  createPrivilegeAccount(createPrivilegeAccountRequest: CreatePrivilegeAccountRequest) {
    this.isCreateProcessActive = true;

    this.graphUserService.CreatePrivilegeAccount(createPrivilegeAccountRequest).subscribe(this.processCreateAccountResponse());
  }

  createServiceAccount(createServiceAccountRequest: CreateServiceAccountRequest) {
    this.isCreateProcessActive = true;

    this.graphUserService.CreateServiceAccount(createServiceAccountRequest).subscribe(this.processCreateAccountResponse());
  }

  resetForm() {
    this.createUserForm.reset();
  }

  getApplicationIdErrorMessage(createUserForm: FormGroup<any>) {
    if (createUserForm.controls.applicationId.errors?.pattern) {
      return "Application Id is invalid";
    }
    return "";
  }

}
