import { Component, OnInit, ViewChild } from "@angular/core";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";
import { ServicePrincipalPermissionRole } from "src/app/models/graph/applications/enums/ServicePrincipalPermissionRole";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IdmServicePrincipalPermissionsModel } from "src/app/models/graph/applications/GraphAppServicePrincipalPermissions";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { GraphAppServicePrincipalRevokeRequestModel, RevokeScopePermission } from "src/app/models/graph/applications/GraphAppServicePrincipalRevokeRequest";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { GraphAppRevokePermissionDisplayModel } from "src/app/models/graph/applications/GraphAppRevokePermissionDisplay";
import Swal from "sweetalert2";
import { CalAngularService } from "@cvx/cal-angular";
import { GraphUserService } from "src/app/services/graph/GraphUser.service";
import { ApplicationRegistrationTenantType } from "src/app/models/ApplicationRegistrationTenantType";
import { csvHelper } from "src/app/helpers/exporter/csvHelper";

@Component({
    selector: 'app-revoke-app-permission',
    templateUrl: './revoke-app-permission.component.html',
    styleUrls: ['./revoke-app-permission.component.css']
})
export class RevokeAppPermissionComponent implements OnInit {

    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        this.revokeablePermissionsDataSource.paginator = value;
    }
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.revokeablePermissionsDataSource.sort = value;
    }

    ngOnInit() {
        this.revokeablePermissionsDataSource.paginator = this.paginator
        this.revokeablePermissionsDataSource.sort = this.sort
    }

    role: ServicePrincipalPermissionRole = ServicePrincipalPermissionRole.Client;
    roleTypes = Object.values(ServicePrincipalPermissionRole)

    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    isLoading: boolean = false;
    isLoadingPermissions: boolean = false;
    isSubmitting: boolean = false;
    isCompleted: boolean = false;
    showAll: boolean = false;

    appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.User
    tenantType: ApplicationRegistrationTenantType = ApplicationRegistrationTenantType.Any

    clientApp?: GraphApplication;
    clientAppIsNonChevron: boolean = false;

    getPermissionsErrorMessage: string;

    revokeablePermissionsDataSource = new MatTableDataSource<GraphAppRevokePermissionDisplayModel>([]);
    selection = new SelectionModel<GraphAppRevokePermissionDisplayModel>(true, []);

    permissionsDisplayedColumns: string[] = ['principalName', 'principalType', 'permissionType', 'permissionValue', 'resourceName'];
    selectPermissionsDisplayedColumns: string[] = ['select', ...this.permissionsDisplayedColumns];

    displayClientApp: any;

    submittedRequests: IWorkflowRequest[] = [];

    ownedApps: GraphApplication[] = [];

    constructor(
        private graphApplicationService: GraphApplicationService,
        private userService: GraphUserService,
        private authService: CalAngularService
    ) { }

    clientAppSelected(data: IGraphApplicationPermissions) {

        this.clientApp = data.application;
        this.clientAppIsNonChevron = this.clientApp.id == '';

        this.displayClientApp = {
            id: data.application.id,
            appId: data.application.appId,
            name: data.application.displayName,
            uri: data.application.identifierUris[0]
        };
        this.getPermissions();
    }

    getPermissions() {
        this.selection.clear();
        this.revokeablePermissionsDataSource.data = [];
        this.getPermissionsErrorMessage = "";
        if (this.clientApp) {
            this.isLoadingPermissions = true;
            this.graphApplicationService.GetServicePrincipalPermissionsAsync(DirectoryDomain.Chevron, this.clientApp.servicePrincipalId, this.role, this.showAll).then((response: IGenericApiResponse<IdmServicePrincipalPermissionsModel>) => {

                let revokeablePermissions: GraphAppRevokePermissionDisplayModel[] = GraphAppRevokePermissionDisplayModel.fromIdmServicePrincipalPermissionsModel(response.data) ?? [];

                this.revokeablePermissionsDataSource.data = revokeablePermissions;

            }).catch((error) => {
                this.getPermissionsErrorMessage = `Error getting permissions: ${error.statusText}`;
            })
                .finally(() => { this.isLoadingPermissions = false; });
        }
    }

    selectPermission(row: GraphAppRevokePermissionDisplayModel) {
        this.selection.toggle(row);
    }

    public doFilterPermissions = (value: any) => {
        this.revokeablePermissionsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    async submitRequest() {
        if (this.clientApp !== undefined && this.selection.selected.length > 0) {

            // scopes
            let revokePermissionIdScopesMap = this.selection.selected
            .filter(x => x.permissionType == GraphAppRevokePermissionDisplayModel.scopePermissionType)
            .reduce((map, x) => {
                const existingValue = map.get(x.permissionId) || '';
                map.set(x.permissionId, `${existingValue} ${x.permissionValue}`.trim());
                return map;
            }, new Map<string, string>());

            let requestRevokeScopeAssignments: RevokeScopePermission[] = Array.from(revokePermissionIdScopesMap.entries())
                .map(([key, value]) => new RevokeScopePermission(key, value.split(' ')));

            // app roles
            let requestRevokeAppRolePermissionIds = this.selection.selected
                .filter(x => x.permissionType == GraphAppRevokePermissionDisplayModel.appRolePermissionType)
                .map(s => s.permissionId);

            let payload = new GraphAppServicePrincipalRevokeRequestModel(
                DirectoryDomain.Chevron,
                this.clientApp.servicePrincipalId,
                this.role,
                requestRevokeAppRolePermissionIds,
                requestRevokeScopeAssignments
            );

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
                    this.submittedRequests = x.requests;
                },
                error: (err: any) => {
                    this.submitErrorMessage.message = err.statusText;
                    this.submitErrorMessage.errors = err?.error?.errors ?? [];
                    this.isSubmitting = false;
                },
                complete: () => {
                    this.isSubmitting = false;
                    this.isCompleted = true;
                }
            };

            this.isSubmitting = true;
            let submitCall = this.graphApplicationService.RevokeServicePrincipalPermissionsAsync(payload);
            submitCall.subscribe(observer);
        }
    }

    async onSubmit() {
        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.clientApp !== undefined && this.selection.selected.length > 0) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#97002E',
                title: '<h3>are you sure?</h3>',
                html:
                    `<p>You are about to revoke ${this.selection.selected.length} permissions, ` +
                    "this operation can't be undone.</p>",
                showCancelButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button',
                    cancelButton: 'button',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    this.submitRequest();
                }
            });
        }
    }

    resetForm() {
        this.clientApp = undefined;
        this.clientAppIsNonChevron = false;
        this.displayClientApp = undefined;
        this.getPermissionsErrorMessage = "";
        this.revokeablePermissionsDataSource.data = [];
        this.selection.clear();
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];
    }

    typeChange(newRole: ServicePrincipalPermissionRole) {
        this.role = newRole
        if (this.clientApp !== undefined) this.getPermissions();
    }

    hasNonChevronReadPermissions(): boolean {
        const isNonChevronReader = this.userService.CheckIsNonChevronReader(this.authService.cvxClaimsPrincipal);
        return isNonChevronReader;
    }

    toggleShowAll() {
        this.showAll = !this.showAll;
        if (!this.showAll) {
            this.selectPermissionsDisplayedColumns = ['select', ...this.permissionsDisplayedColumns];
        }
        else {
            this.selectPermissionsDisplayedColumns = [...this.permissionsDisplayedColumns];
        }
        this.getPermissions();
    }
    exportToCsv() {
        const columns = ['permissionId', 'principalId', ...this.permissionsDisplayedColumns, 'resourceId'];
        const csvData = csvHelper.ConvertToCSV(this.revokeablePermissionsDataSource.data, columns);
        csvHelper.DownloadFile(csvData, `permissions-${this.clientApp?.appId}-${this.role}-${new Date().toISOString()}`);
    }

}