export enum WorkflowRequestTypes {
    ApplicationCreate = 'ApplicationCreate',
    ApplicationNewClientSecret = 'ApplicationNewClientSecret',
    ApplicationDeleteClientSecret = 'ApplicationDeleteClientSecret',
    ApplicationPermissionChange = 'ApplicationPermissionChange',
    ApplicationPermissionGrant = 'ApplicationPermissionGrant',
    ApplicationPermissionRevoke = 'ApplicationPermissionRevoke',
    ApplicationRoleGrant = 'ApplicationRoleGrant',
    GroupMembershipUpdate = 'GroupMembershipUpdate',
    GroupCreate = 'GroupCreate',
    SecretCreate = 'SecretCreate',
    SecretDelete = 'SecretDelete',
    GuestDelete = 'GuestDelete',
    GuestCreate = 'GuestCreate',
    GuestUpdate = 'GuestUpdate',
    SecretUpdatePermissions = 'SecretUpdatePermissions',
    AccountUnlock = 'AccountUnlock',
    ServiceAccountUpdate = 'ServiceAccountUpdate',
    ServiceAccountDelete = 'ServiceAccountDelete',
    PrivilegeAccountDelete = 'PrivilegeAccountDelete',
    CocoUserCreate = 'CocoUserCreate',
    CocoUserUpdate = 'CocoUserUpdate',
    CocoUserDelete = 'CocoUserDelete',
    MfaReset = 'MfaReset',
    PrivilegeAccountCreate = 'PrivilegeAccountCreate',
    ServiceAccountCreate = 'ServiceAccountCreate',
    MailboxAccountCreate = 'MailboxAccountCreate',
}   