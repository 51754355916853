<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="createdApp === undefined">
            <div class="col col-12">
                <h1>create app registration</h1>
                <form class="branded" [formGroup]="appForm">

                    <mat-error></mat-error>

                    <fieldset>

                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>app reg information</strong></p>
                        </legend>

                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                    <mat-label>type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option *ngFor="let appType of appTypes" [value]="appType">{{ appType
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="appForm.controls.type.errors?.required ?? false">Type is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Service Id for the application using this app registration"
                                    subscriptSizing="dynamic">
                                    <mat-label>service id</mat-label>
                                    <input (click)="searchForChevronApplication()" matInput [readonly]="true"
                                        formControlName="chevronApp" required>
                                    <button mat-icon-button matSuffix (click)="searchForChevronApplication()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    @if (appForm.controls.chevronApp.errors?.required ?? false) {
                                    <mat-error>Service Id is required</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        @if(chevronAppEnvironments.length > 0)
                        {
                        <div class="row">
                            <div class="col-12">
                                <span class="float-right">
                                    <mat-chip-set>
                                        @for (env of chevronAppEnvironments; track env; let index = $index)
                                        {
                                        <mat-chip (click)="removeEnvironment(env)"
                                            [class]="env | mapEnvironmentToColorClass">
                                            <mat-label [class]="env | mapEnvironmentToColorClass">{{ env }}</mat-label>
                                            <button (click)="removeEnvironment(env)" matChipRemove
                                                [attr.aria-label]="'remove ' + env">
                                                <mat-icon [class]="env | mapEnvironmentToColorClass">cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        }
                                    </mat-chip-set>
                                </span>
                            </div>
                        </div>
                        }
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Name of the app registration" subscriptSizing="dynamic">
                                    <mat-label>name</mat-label>
                                    <input matInput formControlName="name">
                                    <mat-error *ngIf="appForm.controls.name.errors?.required ?? false">Name is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Global unique identifier of your application (app url should be used)"
                                    subscriptSizing="dynamic">
                                    <mat-label>uri</mat-label>
                                    <input matNativeControl="url" formControlName="uri"
                                        (change)="onUriChange($event.target)">
                                    <mat-error *ngIf="appForm.controls.uri.errors?.required ?? false">Uri is
                                        required</mat-error>
                                    <mat-error *ngIf="appForm.controls.uri.errors?.invalidDomainFormat ?? false">invalid
                                        format, uri needs to start with https</mat-error>
                                    <mat-error
                                        *ngIf="appForm.controls.uri.errors?.invalidDomain || appForm.controls.uri.errors?.pattern ?? false">invalid
                                        domain</mat-error>
                                    <mat-error *ngIf="appForm.controls.uri.errors?.maxlength">Uri must be less than 256
                                        characters</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                    </fieldset>

                    <fieldset formArrayName="urls">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>urls</strong></p>
                        </legend>

                        <button mat-flat-button color="primary" (click)="addUrl()" type="button"
                            class="add-button"><mat-icon>add</mat-icon> url</button>

                        <div class="row" *ngFor="let url of urls.controls; let i=index">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                    <mat-label>reply url</mat-label>
                                    <input matInput [formControlName]="i" matTooltip="reply url" [readonly]="i===0">
                                    <button mat-icon-button matSuffix (click)="removeUrl(i)" *ngIf="i>0">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <mat-error *ngIf="url.errors?.required ?? false">Url is required</mat-error>
                                    <mat-error *ngIf="url.errors?.invalidDomain || url.errors?.pattern ?? false">invalid
                                        domain</mat-error>
                                    <mat-error *ngIf="url.errors?.invalidDomainFormat ?? false">invalid format, url
                                        needs to start with https</mat-error>
                                    <mat-error *ngIf="url.errors?.maxlength ?? false">Url must be less than 256
                                        characters</mat-error>
                                </mat-form-field>

                            </div>
                        </div>
                    </fieldset>

                    <fieldset formGroupName="owners">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>owners</strong></p>
                        </legend>
                        <div style="margin-bottom: 10px;">
                            <button mat-flat-button color="primary" (click)="searchForPrimaryUser('owners')"
                                class="add-button" type="button"><mat-icon>add</mat-icon> owner</button>
                            @if (appForm.controls.owners.length<2) { &nbsp;<span>2 minimum</span>
                                }
                        </div>
                        <div class="row" *ngFor="let owner of owners.controls; let i=index">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                                    subscriptSizing="dynamic">
                                    <mat-label>owner</mat-label>
                                    <input matInput formControlName="displayName" [readonly]="true">
                                    <button mat-icon-button matSuffix (click)="removeOwner(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-error
                                    *ngIf="appForm.controls.owners.invalid && appForm.controls.owners.touched">Requires
                                    at least 2 owners</mat-error>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>additional requested permissions</strong></p>
                        </legend>

                        <button mat-flat-button color="primary" (click)="searchForApplicationPermissions()"
                            class="add-button" type="button"><mat-icon>add</mat-icon> app permissions </button>

                        <table id="additional-permissions" mat-table [dataSource]="additionalPermissionsDataSource"
                            *ngIf="additionalPermissions.length > 0">

                            <caption>request additional api permissions for the application</caption>

                            <ng-container matColumnDef="app">
                                <th mat-header-cell *matHeaderCellDef>application</th>
                                <td mat-cell *matCellDef="let app">{{ app.application.displayName }}</td>
                            </ng-container>

                            <ng-container matColumnDef="permissions">
                                <th mat-header-cell *matHeaderCellDef>permissions</th>
                                <td mat-cell *matCellDef="let app">
                                    <mat-list role="list">
                                        <mat-list-item role="listitem" *ngFor="let perm of app.permissions">
                                            {{ perm.value }}
                                            <div *ngIf="perm.type === 'microsoft.graph.appRole'"
                                                class="badge badge-primary perm-badge">application</div>
                                            <div *ngIf="perm.type === 'microsoft.graph.permissionScope'"
                                                class="badge badge-info perm-badge">delegated</div>
                                        </mat-list-item>
                                    </mat-list>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                    <button mat-icon-button matSuffix (click)="removeAppPermission(row)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="additionalPermissionsColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: additionalPermissionsColumns;"></tr>

                        </table>
                    </fieldset>

                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                        <mat-spinner [diameter]="30" *ngIf="isCreating"
                            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                        <div class="btn-group mr-2" role="group" *ngIf="!isCreating">
                            <button class="button" type="submit" (click)="onSubmit()">create</button>
                        </div>
                        <div class="btn-group mr-2" role="group" *ngIf="!isCreating">
                            <button class="button" type="button" [disabled]="isCreating"
                                (click)="resetForm()">cancel</button>
                        </div>
                    </div>

                    <display-http-error [message]="createErrorMessage.message"
                        [errors]="createErrorMessage.errors"></display-http-error>

                </form>
            </div>
        </div>

        <div class="row" *ngIf="createdApp !== undefined">
            <div class="col col-12">
                <a class="close-button" (click)="createdApp = undefined"><span
                        class="icon-remove close-button"></span></a>
                <h1>app registration created</h1>
                <display-form [displayObject]="displayCreatedApp"></display-form>
                <display-requests [requests]="createdApp.requests"></display-requests>
            </div>
        </div>
    </section>
</main>