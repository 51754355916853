<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if (createdGroup === undefined) {
        <div class="row">
            <div class="col col-12">
                <h1>create group</h1>
                <form class="branded" [formGroup]="groupForm">
                    <mat-error></mat-error>
                    <fieldset>
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>group information</strong></p>
                        </legend>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                    <mat-label>type</mat-label>
                                    <mat-select formControlName="type">
                                        @for(groupType of groupTypes; track groupType)
                                        {
                                            <mat-option [value]="groupType">{{ groupType }}</mat-option>
                                        }
                                    </mat-select>
                                    @if (groupForm.controls.type.errors?.required ?? false) {
                                    <mat-error>Type is required</mat-error>
                                    }

                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Service Id for the application using this app registration"
                                    subscriptSizing="dynamic">
                                    <mat-label>service id</mat-label>
                                    <input matNativeControl="number" formControlName="serviceId">
                                    @if (groupForm.controls.serviceId.errors?.pattern ?? false) {
                                    <mat-error>Service Id must contain only numbers</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100" matTooltip="Name of the group"
                                    subscriptSizing="dynamic">
                                    <mat-label>name</mat-label>
                                    <input matInput formControlName="name">
                                    @if (groupForm.controls.name.errors?.required ?? false) {
                                    <mat-error>Name is required</mat-error>
                                    }

                                    @if (groupForm.controls.name.errors?.maxlength ?? false) {
                                    <mat-error>Name must be no more than
                                        {{groupForm.controls.name.errors?.maxlength.requiredLength}}
                                        characters</mat-error>
                                    }
                                    @if (groupForm.controls.name.errors?.pattern ?? false) {
                                    <mat-error>Name is invalid</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100" matTooltip="Description of the group"
                                    subscriptSizing="dynamic">
                                    <mat-label>description</mat-label>
                                    <input matInput formControlName="description">
                                    @if (groupForm.controls.description.errors?.required ?? false) {
                                    <mat-error>Description is required</mat-error>
                                    }

                                    @if (groupForm.controls.description.errors?.maxlength ?? false) {
                                        <mat-error>Description must be no more than {{groupForm.controls.description.errors?.maxlength.requiredLength}} characters</mat-error>
                                    }

                                </mat-form-field>
                            </div>
                        </div>

                    </fieldset>

                    <fieldset formGroupName="owners">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>owners</strong></p>
                        </legend>

                        <div style="margin-bottom: 10px;">
                            <button mat-flat-button color="primary" (click)="searchForPrimaryUser()" class="add-button"
                                type="button">
                                <mat-icon>add</mat-icon> owner
                            </button>
                            @if (groupForm.controls.owners.length<2) { &nbsp;<span>2 minimum</span>
                                }
                        </div>

                        @for(owner of owners.controls; track owner; let i = $index)
                        {
                            <div class="row">

                                <div class="col-12">
                                    <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                                        subscriptSizing="dynamic">
                                        <mat-label>owner</mat-label>
                                        <input matInput formControlName="displayName" [readonly]="true">
                                        <button mat-icon-button matSuffix (click)="removeOwner(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    @if (groupForm.controls.owners.invalid && groupForm.controls.owners.touched) {
                                        <mat-error>Requires at least 2 owners</mat-error>
                                    }
                                </div>
                            </div>

                        }
                    </fieldset>

                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                        @if (isCreating) {
                        <mat-spinner [diameter]="30"
                            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                        }
                        @if (!isCreating) {
                        <div class="btn-group mr-2" role="group">
                            <button class="button" type="submit" (click)="onSubmit()">create</button>
                        </div>
                        <div class="btn-group mr-2" role="group">
                            <button class="button" type="button" [disabled]="isCreating"
                                (click)="resetForm()">cancel</button>
                        </div>
                        }
                    </div>

                    <display-http-error [message]="createErrorMessage.message"
                        [errors]="createErrorMessage.errors"></display-http-error>

                </form>
            </div>
        </div>
        }
        @else
        {
        <div class="col col-12">
            <a class="close-button" (click)="createdGroup = undefined"><span
                    class="icon-remove close-button"></span></a>
            <h1>group created</h1>
            <display-requests [requests]="createdGroup.requests"></display-requests>
        </div>
        }
    </section>
</main>