<mat-dialog-content>
    <h2>batch {{ data.type | lowercase }} lookup</h2>

    <div class="container mb-3" *ngIf="screenMessage !== ''">
        <p class="text-danger text-center">{{screenMessage}}</p>
    </div>
    
    <div class="row">
        <div class="col-12">
            <div class="alert alert-info">Semi-colon separated list of identifiers</div>
    
            <mat-form-field appearance="outline" class="w-100"
            subscriptSizing="dynamic">
                <mat-label>{{ data.type | lowercase }} identifiers</mat-label>
                <textarea [formControl]="searchTerms" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20"></textarea>
            </mat-form-field>
        </div>
    </div>
    
    <div class="directory-search-wrapper">
    <generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="''"
        [records]="resolvedObjects"
        [displayedColumns]="resolvedCols"
        [selectableRecords]="false"
        [deletableRecords]="false"
        [allowLocalFilter]="false"
        [hidden]="resolvedObjects.length === 0 || isSearching"
    ></generic-search-results>
    </div>
    
    <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
        <div class="btn-group mr-2" role="group" [hidden]="searchTerms.value === ''">
            <button class="button" type="button" (click)="resolve()" [disabled]="isSearching">
                <div class="button-spinner"><mat-spinner [diameter]="20" [hidden]="!isSearching"></mat-spinner></div>  
                lookup <div [hidden]="!isSearching">({{ numLoadedRecordsProgress }} loaded)</div>
            </button>
        </div>
        <div class="btn-group mr-2" role="group" [hidden]="resolvedObjects.length === 0 || isSearching">
            <button class="button" type="button" (click)="add()">add</button>
        </div>
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="reset()">reset</button>                                 
        </div>
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" mat-dialog-close>close</button>                                 
        </div>
    </div>
</mat-dialog-content>