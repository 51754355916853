import { Injectable, NgModule } from "@angular/core";
import { CanActivate, RouterModule } from "@angular/router";
import { NextPageService, NextPageRoute } from '@cvx/nextpage';
import { HomeComponent } from "./components/home/home.component";
import { CreatePrimaryUserComponent } from "./components/account/primaryUserAccounts/create-primary-user/create-primary-user.component";
import { ManagePrimaryUserComponent } from "./components/account/primaryUserAccounts/manage-primary-user/manage-primary-user.component";
import { MyProfileComponent } from "./components/account/selfService/my-profile/my-profile.component";
import { SearchPrimaryUserComponent } from "./components/account/primaryUserAccounts/search-primary-user/search-primary-user.component";
import { InviteGuestComponent } from "./components/account/guest/invite-guest/invite-guest.component";
import { UpdateGuestComponent } from "./components/account/guest/update-guest/update-guest.component";
import { RetireGuestComponent } from "./components/account/guest/retire-guest/retire-guest.component";
import { CreateApplicationComponent } from "./components/applications/create-application/create-application";
import { RequestPermissionsComponent } from "./components/applications/request-permissions/request-permissions";
import { UpdateMembershipComponent } from "./components/groups/update-membership/update-membership.component";
import { MyApprovalRequestsComponent } from "./components/requests/my-approval-requests/my-approval-requests.component";
import { MyRequestsComponent } from "./components/requests/my-requests/my-requests.component";
import { DetailsRequestsComponent } from "./components/requests/details-requests/details-requests.component";
import { CreatePasswordComponent } from "./components/passwords/create-password/create-password.component";
import { DeletePasswordComponent } from "./components/passwords/delete-password/delete-password.component";
import { UpdatePasswordPermissionsComponent } from "./components/passwords/update-password-permissions/update-password-permissions.component";
import { RequestPasswordPermissionsComponent } from "./components/passwords/request-password-permissions/request-password-permissions.component";
import { MfaResetComponent } from "./components/account/mfa-reset/mfa-reset.component";
import { RetireCocoUserComponent } from "./components/account/cocoUsers/retire-coco-user/retire-coco-user.component";
import { UpdateCocoUserComponent } from "./components/account/cocoUsers/update-coco-user/update-coco-user.component";
import { CreateCocoUserComponent } from "./components/account/cocoUsers/create-coco-user/create-coco-user.component";
import { AuthCodeValidationComponent } from "./components/support/auth-code-validation/auth-code-validation.component";
import { ContractorAccessManagementComponent } from "./components/account/primaryUserAccounts/contractor-access-management/contractor-access-management.component";
import { testerGuard } from "./guards/tester.guard";
import { environment } from "src/environments/environment";
import { AssignAppRoleComponent } from "./components/applications/assign-app-role/assign-app-role.component";
import { RegisterClientSecretComponent } from "./components/applications/register-client-secret/register-client-secret.component";
import { UnlockUsersComponent } from "./components/account/unlockUsers/unlock-users.component";
import { UpdateServiceAccountComponent } from "./components/account/servicePrivilegeAccounts/update-service-account/update-service-account.component";
import { RetireServicePrivilegeAccountComponent } from "./components/account/servicePrivilegeAccounts/retire-service-privilege-account/retire-service-privilege-account.component";
import { RouteConstants } from "./models/common/enums/RouteConstants.enum";
import { CreateGroupComponent } from "./components/groups/create-group/create-group.component";
import { CreateServicePrivilegeAccountComponent } from "./components/account/servicePrivilegeAccounts/create-service-privilege-account/create-service-privilege-account.component";
import { RevokeAppPermissionComponent } from "./components/applications/revoke-app-permissions/revoke-app-permission.component";
import { DeleteClientSecretComponent } from "./components/applications/delete-client-secret/delete-client-secret.component";
import { CreateMailboxAccountComponent } from "./components/account/mailbox/create-mailbox-account/create-mailbox-account.component";
import { notGuestUserGuard } from "./guards/notGuestUserGuard.guard";

@Injectable({
    providedIn: "root",
})
export class NonProdGuard implements CanActivate {
    canActivate() {
        return !environment.production;
    }
}

let groupRouteChildren: NextPageRoute[] = [{
    path: '',
    title: 'create group',
    runGuardsAndResolvers: 'always',
    component: CreateGroupComponent
},
{
    path: 'create',
    title: 'create group',
    runGuardsAndResolvers: 'always',
    component: CreateGroupComponent
}];

if (!environment.production) {
    groupRouteChildren = [...groupRouteChildren,
    {
        path: 'update-membership',
        title: 'update membership',
        runGuardsAndResolvers: 'always',
        component: UpdateMembershipComponent
    },
    {
        path: 'update-membership/:domain/:id',
        runGuardsAndResolvers: 'always',
        component: UpdateMembershipComponent
    }
    ];
}

export let routes: NextPageRoute[] = [
    {
        path: 'groups',
        title: 'groups',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        children: groupRouteChildren
    },
    {
        path: 'applications',
        title: 'applications',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        children: [
            {
                path: '',
                title: 'create application',
                runGuardsAndResolvers: 'always',
                component: CreateApplicationComponent
            },
            {
                path: 'create',
                title: 'create application',
                runGuardsAndResolvers: 'always',
                component: CreateApplicationComponent
            },
            {
                path: 'request-permissions',
                title: 'request permissions',
                runGuardsAndResolvers: 'always',
                component: RequestPermissionsComponent
            }
            ,
            {
                path: 'assign-approles',
                title: 'assign approles',
                runGuardsAndResolvers: 'always',
                component: AssignAppRoleComponent
            },
            {
                path: 'register-client-secret',
                title: 'register client secret',
                runGuardsAndResolvers: 'always',
                component: RegisterClientSecretComponent
            },
            {
                path: 'revoke-permissions',
                title: 'revoke permissions',
                runGuardsAndResolvers: 'always',
                component: RevokeAppPermissionComponent
            },
            {
                path: 'delete-client-secret',
                title: 'delete client secret',
                runGuardsAndResolvers: 'always',
                component: DeleteClientSecretComponent
            }
        ]
    },
    {
        path: 'account',
        title: 'accounts',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        megaMenu: {
            customClasses: 'mega-menu',
            customContent: '',
            columnSize: 3
        },
        children: [
            {
                path: '',
                title: 'my profile',
                runGuardsAndResolvers: 'always',
                component: MyProfileComponent
            },
            {
                path: 'selfservice',
                title: 'self service',
                children: [
                    {
                        path: '',
                        title: 'my profile',
                        runGuardsAndResolvers: 'always',
                        component: MyProfileComponent
                    },
                    {
                        path: 'me',
                        title: 'my profile',
                        runGuardsAndResolvers: 'always',
                        component: MyProfileComponent
                    }
                ]
            },
            {
                path: 'primaryaccounts',
                title: 'primary user accounts',
                children: [
                    {
                        path: '',
                        title: 'create new user account',
                        runGuardsAndResolvers: 'always',
                        component: CreatePrimaryUserComponent
                    },
                    {
                        path: 'create',
                        title: 'create new user account',
                        runGuardsAndResolvers: 'always',
                        component: CreatePrimaryUserComponent
                    },
                    {
                        path: 'manage',
                        title: 'manage user account',
                        runGuardsAndResolvers: 'always',
                        component: ManagePrimaryUserComponent
                    },
                    {
                        path: 'search',
                        title: 'search for user account',
                        runGuardsAndResolvers: 'always',
                        component: SearchPrimaryUserComponent
                    },
                    {
                        path: 'cam',
                        title: 'contractor access management',
                        component: ContractorAccessManagementComponent
                    }
                ]
            },
            {
                path: RouteConstants.Guest,
                title: 'guests',
                children: [
                    {
                        path: '',
                        title: 'invite guest',
                        runGuardsAndResolvers: 'always',
                        component: InviteGuestComponent
                    },
                    {
                        path: 'invite',
                        title: 'invite guest',
                        runGuardsAndResolvers: 'always',
                        component: InviteGuestComponent
                    },
                    {
                        path: 'update',
                        title: 'update guest',
                        runGuardsAndResolvers: 'always',
                        component: UpdateGuestComponent
                    },
                    {
                        path: 'delete',
                        title: 'delete guest',
                        runGuardsAndResolvers: 'always',
                        component: RetireGuestComponent
                    }

                ]
            },
            {
                path: RouteConstants.ServicePrivilege,
                title: 'service/privilege',
                children: [
                    {
                        path: '',
                        title: 'unlock',
                        component: UnlockUsersComponent,
                    },
                    {
                        path: 'unlock',
                        title: 'unlock account',
                        component: UnlockUsersComponent,
                    },
                    {
                        path: 'create',
                        title: 'create account',
                        component: CreateServicePrivilegeAccountComponent,
                    },
                    {
                        path: 'update',
                        title: 'update account',
                        component: UpdateServiceAccountComponent,
                    },
                    {
                        path: 'delete',
                        title: 'delete account',
                        component: RetireServicePrivilegeAccountComponent,
                    },
                ]
            },
            {
                path: 'mfa',
                title: 'mfa reset',
                children: [
                    {
                        path: 'reset',
                        title: 'mfa reset',
                        component: MfaResetComponent,
                    },
                ],
            },
            {
                path: RouteConstants.Coco,
                title: 'coco users',
                children: [
                    {
                        path: 'create',
                        title: 'create coco user',
                        component: CreateCocoUserComponent,
                    },
                    {
                        path: 'update',
                        title: 'update coco user',
                        component: UpdateCocoUserComponent,
                    },
                    {
                        path: 'delete',
                        title: 'delete coco user',
                        component: RetireCocoUserComponent,
                    },
                ],
            },
            {
                path: 'mailbox',
                title: 'mailbox accounts',
                children: [
                    {
                        path: '',
                        title: 'create shared mailbox',
                        runGuardsAndResolvers: 'always',
                        component: CreateMailboxAccountComponent
                    },
                    {
                        path: 'create',
                        title: 'create shared mailbox',
                        runGuardsAndResolvers: 'always',
                        component: CreateMailboxAccountComponent
                    }
                ],
            },
        ]
    },
    {
        path: 'passwords',
        title: 'passwords',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        children: [
            {
                path: '',
                title: 'register a password',
                runGuardsAndResolvers: 'always',
                component: CreatePasswordComponent
            },
            {
                path: 'create',
                title: 'register a password',
                runGuardsAndResolvers: 'always',
                component: CreatePasswordComponent
            },
            {
                path: 'delete',
                title: 'delete a password',
                runGuardsAndResolvers: 'always',
                component: DeletePasswordComponent
            },
            {
                path: 'update-permissions',
                title: 'update password permissions',
                runGuardsAndResolvers: 'always',
                component: UpdatePasswordPermissionsComponent
            },
            {
                path: 'request-permissions',
                title: 'request password permissions',
                runGuardsAndResolvers: 'always',
                component: RequestPasswordPermissionsComponent
            }
        ]
    },
    {
        path: 'requests',
        title: 'requests',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        children: [
            {
                path: '',
                title: 'my requests',
                runGuardsAndResolvers: 'always',
                component: MyRequestsComponent
            },
            {
                path: 'my-requests',
                title: 'my requests',
                runGuardsAndResolvers: 'always',
                component: MyRequestsComponent
            },
            {
                path: 'my-approvals',
                title: 'my approvals',
                runGuardsAndResolvers: 'always',
                component: MyApprovalRequestsComponent
            },
            {
                path: ':type/:id',
                component: DetailsRequestsComponent,
            }
        ]
    },
    {
        path: 'support',
        title: 'support',
        canActivate: [testerGuard, notGuestUserGuard],
        canActivateChild: [testerGuard, notGuestUserGuard],
        children: [
            {
                path: 'authcodevalidation',
                title: 'authorization code validation',
                component: AuthCodeValidationComponent
            }
        ]
    },
    {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        // reuse HomeComponent as it doesn't have any api calls
        data: { reuseComponent: true }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(private NP: NextPageService) {
        this.NP.options.navigationRoutes = routes;
    }
}
