
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { FormControlStatus } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IUserUpdate } from "src/app/models/userUpdate";
import { DialogDirectorySearchComponent } from "../../dialog-directory-search/dialog-directory-search.component";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { UserUpdateFormComponent } from "../user-update-form/user-update-form.component";
import { FormService } from "src/app/services/form.service";

@Component({
  selector: 'app-user-update-list',
  templateUrl: './user-update-list.component.html',
})
export class UserUpdateListComponent implements OnInit, OnChanges {
  @Input() caption: string = '';
  @Input() records: IUserUpdate[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() userType: UserType | undefined;
  @Output() public onRecordDeleted = new EventEmitter<IUserUpdate>();
  @Output() public onUserInfoChange: EventEmitter<any[]> =
    new EventEmitter<any[]>();

  @ViewChildren("userUpdateForm") userUpdateForms: QueryList<UserUpdateFormComponent>
  cols: string[];
  dataSource = new MatTableDataSource<IUserUpdate>();

  public showBulkServiceIdSearch: boolean = false;
  public showBulkStewardSearch: boolean = false;
  public showBulkStewardLabel: string = "";
  public showBulkStewardTooltipMessage: string = "";
  public selectedSteward: IDirectoryUser | null = null;
  public bulkServiceId: string | null = null;

  constructor(
    private readonly dialog: MatDialog, private readonly formService: FormService) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.records;
    this.cols = [...this.displayedColumns, 'del'];
  }

  ngOnChanges(_: SimpleChanges): void {
    this.dataSource.data = this.records;

    this.showBulkServiceIdSearch = this.userType === UserType.Service;;
    this.showBulkStewardSearch = this.userType !== UserType.Coco;

    switch (this.userType) {
      case UserType.Guest:
        this.showBulkStewardLabel = `bulk ${this.formService.getStewardSponsorLabel(this.userType)}`;
        this.showBulkStewardTooltipMessage = `new ${this.formService.getStewardSponsorLabel(this.userType).toLocaleLowerCase()} for all selected guests; ${this.formService.getStewardSponsorTooltipRule(this.userType)}`;
        break;
      default:
        this.showBulkStewardLabel = "bulk steward";
        this.showBulkStewardTooltipMessage = "New Steward for all selected accounts";
        break
    }
  }

  deleteRow(selected: IUserUpdate) {
    this.onRecordDeleted.emit(selected);
  }

  public onFormStatusChange(
    formControlStatus: FormControlStatus,
    rowIndex: number
  ) {
    this.records[rowIndex].isValid = formControlStatus === 'VALID';
    this.records = [...this.records];

    this.onUserInfoChange.emit(this.records);
  }

  public get isCocoUserType() {
    return this.userType === UserType.Coco;
  }

  public onFormValueChanges(updateUserInfo: any, rowIndex: number) {
    this.records[rowIndex].updateUserInfo = updateUserInfo;
    this.records = [...this.records];
    this.onUserInfoChange.emit(this.records);
  }

  serviceIdChanged(event: any) {
    if (event) {
      const newApplicationId: string | null = event.target.value;
      if (this.userUpdateForms && newApplicationId) {
        this.bulkServiceId = newApplicationId;
        this.userUpdateForms.forEach(userUpdateForm => {
          userUpdateForm.setApplicationId(newApplicationId);
        });
      }
    }
  }
  searchForUsers() {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.User,
        domain: DirectoryDomain.Chevron,
        userType: UserType.Primary,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: false,
        filterGroupOnlyManagedByIdamp: false
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe((result: IDirectoryUser) => {
      if (result) {
        // override toString method to display displayName (as form control value will display [object Object] without this)
        result.toString = () => result.displayName;
        this.selectedSteward = result;

        if (this.userUpdateForms) {
          this.userUpdateForms.forEach(userUpdateForm => {
            userUpdateForm.setStewardInfo(result.id, result.displayName);
          });
        }
      }
    });
  }
}
