import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControlStatus, FormGroup, Validators } from '@angular/forms';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { GroupMemberType } from 'src/app/models/directory/enums/GroupMemberType.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { DialogDirectorySearchComponent } from '../../dialog-directory-search/dialog-directory-search.component';
import { IDialogDirectoryData } from 'src/app/models/components/IDialogDirectoryData';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';

import { distinctUntilChanged } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/services/form.service';
import { CocoUserTitle } from 'src/app/models/cocoUser/CocoUserTitle.enum';

@Component({
  selector: 'app-user-update-form',
  templateUrl: './user-update-form.component.html',
})
export class UserUpdateFormComponent {
  @Input() userInfo: IDirectoryUser;
  @Output() public onFormStatusChange: EventEmitter<FormControlStatus> =
    new EventEmitter<FormControlStatus>();

  @Output() public onFormValueChanges: EventEmitter<any> =
    new EventEmitter<any>();

  public updateForm: FormGroup = new FormGroup({});
  public get UserType() { return UserType; }

  public get titles() { return Object.values(CocoUserTitle) };

  public stewardSponsorLabel: string = "";
  public stewardSponsorTooltipMessage: string = "";

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly dialog: MatDialog,
  ) { }

  ngOnChanges() {
    this.setCurrentUserInfo();
    this.stewardSponsorLabel = this.formService.getStewardSponsorLabel(this.userInfo.type);
    this.stewardSponsorTooltipMessage = this.formService.getStewardSponsorTooltipRule(this.userInfo.type);

  }

  ngOnInit(): void {
    this.initForm();

    this.updateForm.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe((status) => {
        this.onFormStatusChange.emit(status);
      });

    this.updateForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((changes: any) => {
        this.onFormValueChanges.emit(changes);
      });

    this.formService.getIsValidateUpdateUserForms().subscribe({
      next: (isValidate: boolean) => {
        if (isValidate) {
          this.formService.validateAllFields(this.updateForm);
        }
      },
    });
  }

  public searchForUsers() {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.User,
        domain: DirectoryDomain.Chevron,
        userType: UserType.Primary,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: false,
        filterGroupOnlyManagedByIdamp: false
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe((result: IDirectoryUser) => {
      if (result) {
        this.setStewardInfo(result.id, result.displayName);
      }
    });
  }

  private normalizeCocoUserTitle(title: string): string {
    const jobTitlePieces = title.split(' ');

    if (jobTitlePieces.length > 1) {
      return jobTitlePieces.map(element => {
        return `${element.charAt(0).toUpperCase()}${element.slice(1).toLowerCase()}`;
      }).join(' ');
    }

    return title;
  }

  public getMobileNumberErrorMessage(updateUserForm: FormGroup<any>) {
    if (updateUserForm.controls.mobilePhone.errors?.pattern) {
      return "Mobile Number is invalid";
    }
    return "";
  }

  public getTelephoneNumberErrorMessage(updateUserForm: FormGroup<any>) {
    if (updateUserForm.controls.telephoneNumber.errors?.required) {
      return "Telephone Number is required";
    }
    else if (updateUserForm.controls.telephoneNumber.errors?.pattern) {
      return "Telephone Number is invalid";
    }
    return "";
  }

  public getStationNumberErrorMessage(updateUserForm: FormGroup<any>) {
    if (updateUserForm.controls.stationNumber.errors?.required) {
      return "Station Number is required";
    }
    else if (updateUserForm.controls.stationNumber.errors?.pattern) {
      return "Station Number is invalid";
    }
    else if (updateUserForm.controls['stationNumber'].hasError('minlength')) {
      return "Station Number must have at least 2 characters";
    }
    else if (updateUserForm.controls['stationNumber'].hasError('maxlength')) {
      return `Station Number must be no more than ${updateUserForm.controls['stationNumber'].errors?.maxlength.requiredLength} characters`;
    }
    return "";
  }

  public getApplicationIdErrorMessage(updateUserForm: FormGroup<any>) {
    if (updateUserForm.controls.applicationId.errors?.pattern) {
      return "New Service Id is invalid";
    }
    return "";
  }

  private setCurrentUserInfo() {
    if (this.userInfo) {
      switch (this.userInfo.type) {
        case UserType.Coco:
          this.setCocoUserCurrentInfo();
          break;
        case UserType.Guest:
          this.setUserNameCurrentInfo();
          break;
      }

      //Propogating the initial values
      this.onFormStatusChange.emit("VALID");
      this.onFormValueChanges.emit(this.updateForm.value);
    }
  }

  public setStewardInfo(stewardUserId: string, stewardDisplayName: string) {
    if (stewardUserId) {
      this.updateForm.controls['stewardUserId']?.setValue(stewardUserId);
      this.updateForm.controls['selectedSteward']?.setValue(stewardDisplayName);
      this.onFormValueChanges.emit(this.updateForm.value);
    }
  }

  public setApplicationId(applicationId: string) {
    if (applicationId) {
      this.updateForm.controls['applicationId']?.setValue(applicationId);
      this.onFormValueChanges.emit(this.updateForm.value);
    }
  }

  private setCocoUserCurrentInfo() {
    this.setUserNameCurrentInfo();
    this.updateForm.controls['stationNumber']?.setValue(this.userInfo.department?.replace('Station ', '')); // remove 'Station ' from the department
    this.updateForm.controls['jobTitle']?.setValue(this.normalizeCocoUserTitle(this.userInfo.jobTitle));
    this.updateForm.controls['telephoneNumber']?.setValue(this.userInfo.businessPhone?.replace('+1 ', ''));
    this.updateForm.controls['mobilePhone']?.setValue(this.userInfo.mobilePhone?.replace('+1 ', ''));
  }

  private setUserNameCurrentInfo() {
    this.updateForm.controls['givenName']?.setValue(this.userInfo.firstName);
    this.updateForm.controls['surname']?.setValue(this.userInfo.lastName);
  }

  private initForm() {
    switch (this.userInfo.type) {
      case UserType.Coco:
        this.initCocoUserForm();
        break;
      case UserType.Guest:
        this.initGuestForm();
        break;
      case UserType.Service:
        this.initServiceAccountForm();
        break;
    }
  }

  private initGuestForm() {
    this.updateForm = this.formBuilder.group({
      givenName: [
        '',
        Validators.compose([
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      surname: [
        '',
        Validators.compose([
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      selectedSteward: [""],
      stewardUserId: [""]
    });
    this.setCurrentUserInfo();
  }

  private initCocoUserForm() {
    this.updateForm = this.formBuilder.group({
      givenName: [
        '',
        Validators.compose([
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      surname: [
        '',
        Validators.compose([
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      stationNumber: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern(ValidationPatternConstants.NumberPattern)
      ]),],
      telephoneNumber: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidationPatternConstants.PhoneNumberPattern)
      ])],
      mobilePhone: ['', Validators.compose([
        Validators.pattern(ValidationPatternConstants.PhoneNumberPattern)
      ])],
      jobTitle: ['', Validators.compose([
        Validators.required
      ])],
      selectedSteward: [""],
      stewardUserId: [""]
    });

    this.setCurrentUserInfo();
  }

  private initServiceAccountForm() {
    this.updateForm = this.formBuilder.group({
      applicationId: ['',
        Validators.compose([
          Validators.pattern(ValidationPatternConstants.NumberPattern),
        ]),
      ],
      selectedSteward: [""],
      stewardUserId: [""]
    });
    this.setCurrentUserInfo();
  }
}
