<main class="container">
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    <div class="row" *ngIf="createdUser === undefined">
      <div class="col col-12">
        <h1>create account</h1>
        <form *ngIf="createUserForm" [formGroup]="createUserForm" class="branded text-left" novalidate
          (ngSubmit)="onSubmit()">
          <mat-error></mat-error>
          <fieldset>
            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
              <p><strong>{{ createUserForm?.get('accountType')?.value?.toLowerCase() ?? '' }} account information</strong></p>
            </legend>
            <div class="row">
              <div class="col-12">
                <div id="trainingCompletionContainer">
                  <input id="confirm" type="checkbox" name="trainingCompletionCheckbox"
                    formControlName="trainingCompletionCheckbox" class="form-control" />
                  <span id="confirmStatement" class="text-left">
                    Confirm completion of <a target="_blank" rel="noopener" href="https://wd5.myworkday.com/chevron/d/inst/1$23455/23455$1113.htmld">CORPCOMP Privileged User</a> training*</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Account Type">
                  <mat-label>account type</mat-label>
                  <mat-select formControlName="accountType" required>
                    <mat-option value="Service">Service</mat-option>
                    <mat-option value="Privilege">Privilege</mat-option>
                  </mat-select>
                  <mat-error *ngIf="createUserForm.controls.accountType.errors?.required ?? false">Account Type is
                    required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Account Name">
                  <mat-label>account name</mat-label>
                  <input matInput formControlName="givenName" required
                    [readonly]="createUserForm.controls.accountType.value === 'Privilege'">
                  <mat-error *ngIf="createUserForm.controls.givenName.errors?.required ?? false">
                    Account Name is required
                  </mat-error>
                  <mat-error *ngIf="createUserForm.controls.givenName.errors?.pattern ?? false">
                    Account Name is invalid
                  </mat-error>
                  <mat-error *ngIf="createUserForm.controls.givenName.errors?.maxlength ?? false">
                    Account Name must be no more than {{
                    createUserForm.controls.givenName.errors?.maxlength.requiredLength }}
                    characters
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="createUserForm.controls.accountType.value === 'Service'">
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                  matTooltip="Used to determine which services can be enabled (some countries do not allow all services)">
                  <mat-label>usage location</mat-label>

                  <input name="residenceName" matInput
                    formControlName="residenceName" [matAutocomplete]="auto" />

                  <input type="hidden" name="usageLocationCode" formControlName="usageLocationCode" />

                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="onValueSelected($event.option.value)">
                    <mat-option *ngFor="let address of filterOptions | async" [value]="address">{{ address.name
                      }}</mat-option>
                  </mat-autocomplete>

                  <mat-error *ngIf="createUserForm.controls.residenceName.errors?.required ?? false">
                    Usage Location is required
                  </mat-error>
                  <mat-error *ngIf="createUserForm.controls.residenceName.errors?.pattern ?? false">
                    Usage Location is invalid
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Application Id should contain only numbers">
                  <mat-label>application id</mat-label>
                  <input matInput formControlName="applicationId">
                  <mat-error *ngIf="createUserForm.controls.applicationId.errors">
                    {{getApplicationIdErrorMessage(createUserForm)}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
            <mat-spinner [diameter]="30" *ngIf="isCreateProcessActive"
              style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
            <div class="btn-group mr-2" role="group" *ngIf="!isCreateProcessActive">
              <button class="button" type="submit">create</button>
            </div>
            <div class="btn-group mr-2" role="group" *ngIf="!isCreateProcessActive">
              <button class="button" type="button" [disabled]="isCreateProcessActive"
                (click)="resetForm()">cancel</button>
            </div>
          </div>

          <display-http-error [message]="createErrorMessage.message"
            [errors]="createErrorMessage.errors"></display-http-error>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="createdUser !== undefined">
      <div class="col col-12">
        <a class="close-button" [routerLink]="['/account/serviceprivilege/create']"><span
            class="icon-remove close-button"></span></a>
        <h1>{{ createUserForm?.get('accountType')?.value?.toLowerCase() ?? '' }} account created</h1>
        <display-form [displayObject]="displayCreatedUser"></display-form>

        <display-requests [requests]="createdUser.requests"></display-requests>
      </div>
    </div>

  </section>
</main>