import { DirectoryGroupType } from "../enums/DirectoryGroupType";
export type serviceIdType = string | null | undefined;
export class DirectoryGroupCreateRequest{

    constructor(displayName: string, owners: string[], type: DirectoryGroupType, description: string, serviceId: serviceIdType) {
        this.groups = [new DirectoryGroupRequestItem(displayName, owners, type, description, serviceId)];
    }

    groups: DirectoryGroupRequestItem[] = [];
}

export class DirectoryGroupRequestItem{

    constructor(displayName: string, owners: string[], type: DirectoryGroupType, description: string, serviceId: serviceIdType){
        this.displayName = displayName;
        this.owners = owners;
        this.type = type;
        this.description = description;
        this.serviceId = serviceId;
    }

    displayName: string = '';
    owners: string[];
    type: DirectoryGroupType;
    description: string = '';
    serviceId: serviceIdType;
}