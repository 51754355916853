<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">

        <div class="container" *ngIf="screenMessage !== ''">
            <p class="text-danger text-center">{{screenMessage}}</p>
        </div>

        <div class="row" *ngIf="isSearching">
            <div class="col col-12">
                <h1>Workflow Request</h1>

                <div class="row justify-content-center col-sm-12">
                    <p>Loading request...</p>
                </div>
                <div class="row justify-content-center col-sm-12">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="doneLoading">
            <div class="col col-12">
                <h1>Workflow Request</h1>

                <div class="mt-4 mb-3">
                    <display-form [displayObject]="requestDisplayObject" [displayFields]="requestDisplayProperties"
                        class="mt-4"></display-form>

                    <div *ngIf="canCancel" class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                        <mat-spinner [diameter]="30" *ngIf="isCancelling"
                            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                        <div class="btn-group mr-2" role="group" *ngIf="!isCancelling">
                            <button class="button" (click)="cancelRequest()" type="submit">cancel request</button>
                        </div>
                    </div>
                    <display-http-error [message]="cancellErrorMessage.message"
                        [errors]="cancellErrorMessage.errors"></display-http-error>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="workflowRequest !== undefined" [hidden]="!doneLoading">
            <div class="col col-12">
                <h3>request details</h3>
                <div class="mt-4 mb-3">
                    <request-details-app-role-assignment [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationRoleGrant">
                    </request-details-app-role-assignment>

                    <request-details-app-permissions-change
                        [clientAppId]="workflowRequest.requestDetails.clientObjectId"
                        [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')" *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationPermissionChange ||
                        workflowRequest.type === WorkflowRequestTypes.ApplicationPermissionGrant">
                    </request-details-app-permissions-change>

                    <request-details-app-permission-revoke [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationPermissionRevoke">
                    </request-details-app-permission-revoke>

                    <request-details-group-membership-change [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.GroupMembershipUpdate"></request-details-group-membership-change>

                    <request-details-app-create [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationCreate"></request-details-app-create>
                    <request-details-secret-create [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.SecretCreate"></request-details-secret-create>
                    <request-details-secret-delete [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.SecretDelete"></request-details-secret-delete>
                    <request-details-secret-update-permissions [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.SecretUpdatePermissions"></request-details-secret-update-permissions>
                    <request-details-mfa-reset [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.MfaReset"></request-details-mfa-reset>
                    <request-details-user-delete [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.GuestDelete 
                                || workflowRequest.type === WorkflowRequestTypes.CocoUserDelete
                                || workflowRequest.type === WorkflowRequestTypes.ServiceAccountDelete
                                || workflowRequest.type === WorkflowRequestTypes.PrivilegeAccountDelete"></request-details-user-delete>
                    <request-details-guest-invite [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.GuestCreate"></request-details-guest-invite>
                    <request-details-guest-update [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.GuestUpdate"></request-details-guest-update>
                    <request-details-account-unlock [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.AccountUnlock"></request-details-account-unlock>
                    <request-details-service-account-update [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ServiceAccountUpdate"></request-details-service-account-update>
                    <request-details-coco-create [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.CocoUserCreate"></request-details-coco-create>
                    <request-details-app-register-client-secret [requestData]="workflowRequest.requestDetails"
                        [successes]="workflowRequest.successes" (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationNewClientSecret"></request-details-app-register-client-secret>
                    <request-details-app-delete-client-secret [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ApplicationDeleteClientSecret"></request-details-app-delete-client-secret>
                    <request-details-group-create [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.GroupCreate"></request-details-group-create>
                    <request-details-user-create [workflowRequestType]="workflowRequest.type"
                        [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.ServiceAccountCreate || 
                        workflowRequest.type === WorkflowRequestTypes.PrivilegeAccountCreate"></request-details-user-create>
                    <request-details-coco-update [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.CocoUserUpdate"></request-details-coco-update>

                    <request-details-mailbox-create [requestData]="workflowRequest.requestDetails"
                        (doneLoading)="componentLoaded($event, 'details')"
                        *ngIf="workflowRequest.type === WorkflowRequestTypes.MailboxAccountCreate"></request-details-mailbox-create>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="workflowRequest !== undefined"
            [hidden]="!doneLoading || requestDisplayObject.approvers.length === 0">
            <div class="col col-12">
                <h3>approvals</h3>
                <request-details-approvals [workflowRequest]="workflowRequest" [principal]="currentUserProfile"
                    (doneLoading)="componentLoaded($event,'approvals')">
                </request-details-approvals>
            </div>
        </div>
    </section>
</main>