import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { DirectoryGroupMemberRequest, DirectoryGroupUpdateMemberRequest } from "src/app/models/directory/groups/DirectoryGroupUpdateMemberRequest";
import { IIdAndDomain } from "src/app/models/directory/IIdAndDomain";
import { DirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { IGroupMember } from "src/app/models/components/IGroupMember";

@Component({
    selector: 'request-details-group-membership-change',
    templateUrl: 'request-details-group-membership-change.component.html'
})

export class RequestDetailsGroupMembershipChange implements OnInit {

    @Input() requestData:DirectoryGroupUpdateMemberRequest;
    @Output() doneLoading = new EventEmitter<boolean>();

    group:DirectoryGroup;
    displayGroup:any;
    membersAdded:IGroupMember[] = [];
    membersRemoved:IGroupMember[] = [];
    memberColumns:string[] = ['displayName','mail','type'];

    constructor(private directoryGroupService: DirectoryGroupService, private directoryUserService: DirectoryUserService, private graphAppService:GraphApplicationService){}

    async ngOnInit(): Promise<void>{
        if (this.requestData.groupId !== undefined && this.requestData.groupDomain !== undefined){
            await this.loadGroup(this.requestData.groupId, this.requestData.groupDomain);

            await this.loadMembers(this.requestData.addMembers, this.membersAdded);
            await this.loadMembers(this.requestData.removeMembers, this.membersRemoved);

            this.isDoneLoading();
        }
    }

    async loadGroup(id:string,domain:string) {
        try 
        {
            let result = await this.directoryGroupService.GetByIdAsync(id,domain);
            this.group = result.data;
            this.displayGroup = {
                id: result.data.id,
                name: result.data.displayName,
                domain: result.data.domain,
                type: result.data.type,
                description: result.data.description,
                jusification: this.requestData.businessJustification
            };
        }
        catch (e) {
            this.group = { displayName: 'error loading group' } as DirectoryGroup;
            this.displayGroup = {
                name: `error loading group with id ${id}`,
                domain: domain,
                description: `error loading ${e}`
            };
        }

        this.isDoneLoading();
    }

    async loadMembers(memberRequests:DirectoryGroupMemberRequest[],array:IGroupMember[]) {

        let members:IGroupMember[] = [];

        for(let mr of memberRequests) {
            try
            {
                switch(mr.type){
                    case GroupMemberType.Group: 
                        const group = await this.directoryGroupService.GetByIdAsync(mr.id,mr.domain);
                        members.push({ displayName: group.data.displayName, id: group.data.id, type: `${group.data.directoryObjectType} (${group.data.type})` } as IGroupMember);
                        break;
                    case GroupMemberType.ServicePrincipal: 
                        let sp = await this.graphAppService.GetServicePrincipalAsync(mr.id);
                        members.push({ displayName: sp.data.displayName, id: sp.data.servicePrincipalId, type: GroupMemberType.ServicePrincipal } as IGroupMember);
                        break;
                    case GroupMemberType.User: 
                        const user = await this.directoryUserService.GetByIdAsync({ id: mr.id, domain: mr.domain } as IIdAndDomain); 
                        members.push({ displayName: user.displayName, id: user.id, type: `${user.directoryObjectType} (${user.type})` }as IGroupMember);
                        break;
                }
            }
            catch (error) 
            {
                members.push({ displayName: `unknown ${mr.type} with id ${mr.id}`, mail: '' } as DirectoryObjectBase);
            }
        }

        array.push.apply(array,members);
        this.isDoneLoading();
    }

    async loadUsers(ids:IIdAndDomain[], array:DirectoryUser[]) {
        let users:DirectoryUser[] = [];
        for (let id of ids){
            try
            {
                const user = await this.directoryUserService.GetByIdAsync(id); 
                users.push(user);
            }
            catch (error)
            {
                users.push({ displayName: `unknown user with id ${id.id}`, mail: '' } as DirectoryUser);
            }
        }
        array.push.apply(array,users);
        this.isDoneLoading();
    }

    isDoneLoading() {
        if (this.group !== undefined && 
            (this.requestData.addMembers.length === this.membersAdded.length) && 
            (this.requestData.removeMembers.length === this.membersRemoved.length))
        {
            this.doneLoading.emit(true);
        }
    }
}