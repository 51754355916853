import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataFormatter } from "src/app/services/common/DataFormatter";

@Component({
    selector: 'generic-search-results',
    templateUrl: './generic-search-results.component.html',
    styleUrls: ['./generic-search-results.component.css']
})

export class GenericSearchResultsComponent<TData> implements OnInit, OnChanges, AfterViewInit {
    @Input() showHeader: boolean = false;
    @Input() headerText: string = 'search results';
    @Input() showCounter: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() title: string = 'records found';
    @Input() records: TData[] = [];
    @Input() displayedColumns: string[] = [];
    @Input() selectableRecords: boolean = true;
    @Input() deletableRecords: boolean = false;
    @Input() sortable: boolean = false;
    @Input() defaultSortColumn: string = '';
    @Input() defaultSortAsc: boolean = true;
    @Input() allowLocalFilter: boolean = true;
    @Input() caption: string = this.title;
    @Input() makeSticky: boolean = false;

    @Input() showPagination: boolean = true;

    @Output() recordSelected = new EventEmitter<TData>();
    @Output() recordDeleted = new EventEmitter<TData>();
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    copiedToClipboardId = -1;
    cols: string[];
    dataSource = new MatTableDataSource<TData>();
    defaultSortDirection: string;

    @ViewChild(MatSort) sort: MatSort;

    /**
     *
     */
    constructor(private formatter: DataFormatter) {
    }

    ngOnInit(): void {
        this.dataSource.data = this.records;
        this.cols = [...this.displayedColumns];
        if (this.deletableRecords) {
            this.cols.push('del');
        }

        if (this.sortable) {
            this.defaultSortColumn = this.defaultSortColumn != '' ? this.defaultSortColumn : this.displayedColumns[0];
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.sortable) {
                this.sort.sort(({ id: this.defaultSortColumn, start: this.defaultSortAsc ? 'asc' : 'desc' }) as MatSortable);
                this.dataSource.sort = this.sort;
            }
            this.dataSource.paginator = this.paginator;
        });
    }

    ngOnChanges(_: SimpleChanges): void {
        this.dataSource.data = this.records;
    }

    selectRow(selected: TData) {
        if (this.selectableRecords) {
            this.recordSelected.emit(selected);
        }
    }

    deleteRow(selected: TData) {
        if (this.deletableRecords) {
            this.recordDeleted.emit(selected);
        }
    }

    public doFilter = (value: any) => {
        this.dataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    public onClipboardCopy(successful: boolean, id: number): void {
        if (successful) this.copiedToClipboardId = id;
    }

    public resetClipBoardStatus(): void {
        this.copiedToClipboardId = -1;
    }
}